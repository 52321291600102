import React from 'react';
import { graphql } from "gatsby"

import Layout from '../components/Layout';



const IndexPage = ({data}) => {
  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  return (<Layout activeLink="what-we-do">
		       <div className="background--light">
							<div className="wrapper">
									<div className="container">
											<div className="row">
													<div className="col-sm-24 box--reset-sm">
															<div className="page-head">
																	<h1>What we do</h1>
															</div>
													</div>
											</div>
									</div>
							</div>
					</div>
					{data.allMarkdownRemark.edges.map(({ node }) => (
					<div key={'list-'+node.id}  className="background--gradient">
								<div className="wrapper">
										<div className="box box--y-large">
												<div className="container">
														<div className="row">
																<div className="col-sm-24 text-center">
																		<h2>{node.frontmatter.title}</h2>
																		<div dangerouslySetInnerHTML={{ __html: node.html }}></div>
																</div>
														</div>
														<div className="card__row">
														{node.frontmatter.cards.map(( card, index ) => (
																<div key={'card-'+node.id+'-'+index} className="card">
																		<a href={card.instagram} className="card__image card__image--has-hover" target="_blank" rel="noreferrer">
																				<div className="card__overlay"></div>
																				<img src={card.url} alt={node.frontmatter.title}/>
																				<span className="visually-hidden">View {node.frontmatter.title} on Instagram</span>
																		</a>
																</div>
															))}
														</div>
												</div>
										</div>
								</div>
						</div>
					 	))}
  </Layout>
  );
};

export const query = graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___rank], order: ASC }) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              slug
							rank
							cards { 
								url
								instagram
							}
						}
						html
          }
        }
      }
    }  
`
export default IndexPage;